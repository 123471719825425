<template>
	<v-container class="main-login">
		<div
			class="main-login__bg"
			:style="{
				backgroundImage:
					'url(' + require('@/assets/images/background.jpg') + ')',
			}"
		>
			<img
				class="main-login__bg-logo"
				:src="require('@/assets/images/logo-confipetrol.png')"
				alt=""
			/>
		</div>
		<div class="main-login__form">
			<reset-password />
		</div>
	</v-container>
</template>

<script>
import ResetPassword from '@/components/auth/ResetPassword/ResetPassword.vue';
export default {
	name: 'ResetPasswordView',

	components: {
		ResetPassword,
	},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/login.scss';
</style>
