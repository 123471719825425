import { mapActions } from 'vuex';
import { $EventBus } from '@/main';

export default {
	name: 'FormLogin',
	data: () => ({
		show1: false,
		valid: true,
		email: '',
		emailRules: [
			(v) => !!v || 'Campo requerido',
			(v) =>
				!!(v || '').match(
					/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
				) || 'Correo invalidó',
		],
		error: null,
		isLoadingData: false,
	}),
	
	mounted() {},

	methods: {
		...mapActions('auth', ['passwordRecovery']),
		async validate() {
			let isvalidated = this.$refs.form.validate();
			if (isvalidated) {
				this.isLoadingData = true;
				this.error = null;

				const bodyData = {
					email: this.email,
					application_id: parseInt(process.env.VUE_APP_ID),
				};
				const { ok, message } = await this.passwordRecovery(bodyData);
				if (ok) {
					$EventBus.$emit('showSnack', 'success', message);
					this.reset();
					this.$router.push({ name: 'Login' });
				} else {
					if (Array.isArray(message)) {
						this.error = message.join(' ');
					} else {
						this.error =
							message ||
							'Ha ocurrido un error, por favor inténtelo de nuevo más tarde';
					}
				}
				this.isLoadingData = false;
			}
		},
		reset() {
			this.$refs.form.reset();
		},
		resetValidation() {
			this.$refs.form.resetValidation();
		},
	},
};
